const $clone = require('lodash.clone')
const $merge = require("lodash.merge");
const config = require("./config.base");
const loadShell = require("./utils/voicerconfig/load-shell");

const configEnvironments = {
  development: require("./config.development"),
  staging: require("./config.staging"),
  production: require("./config.production"),
  next: require("./config.next")
};

let configLocal = require("./utils/voicerconfig/load-local");
let envConfig = null;

if (process.env.ADD_CONFIG_OPTIONS) {
  try {
    console.log(`🤓 use environment options`);
    envConfig = JSON.parse(process.env.ADD_CONFIG_OPTIONS);
  } catch (error) {
    console.error(`Invalid environment options`);
    console.error(`You passed`, process.env.ADD_CONFIG_OPTIONS);
    console.error(error);
    process.exit(1);
  }
}
const findTag = (slug, tags) => {
  return tags.find((tag) => tag.slug === slug)
}
const transformConfig = (config) => {
  if (config.useShell) {
    config = loadShell(config)
  }
  return config
}

/**
 * default configuration file
 * common no matter the BROCOLI_API_MODE
 * you can override properties in
 * config.[BROCOLI_API_MODE].js configurations files
 */
const endConfig = $merge(
  {},
  config,
  {
    /**
     * common configuration here
     */
    siteId: "5ede275c55a10950769c5928",
    authMode: [
      'local'
    ],
    authLocalOptions: {
      mode: 'otp',
    },
    enableExperimentalBlocks: false,
    showCardDescription: true,
    enableSplashScreen: 'wavy',
    supportEmailAddress: "contact@radiotraitdunion.fr",
    metadatas: {
      name: `Radio Trait d'Union`,
      colorPrimary: "#bcbe07"
    },
    theme: {
      fontFamily: "Cabin",
      fonts: {
        google: {
          families: [ "Cabin:100,300,400,500,700,900", "Material+Icons" ],
          downloadFonts: true
        }
      }
    },
    analytics: {
      mixpanel: "8f12679cc2d605f877f5d327ea08b304",
      matomo: "19"
    },
    useLTS: true,
    useShell: [
      "bb-default",
      {
        view: {
          allowHeadingOnMobile: false,
        },
        mode: "universes",
        universes: [
          {
            type: "tag",
            tagId: "677e40158282cd6296ec3487",
            i18n: {
              fr: {
                title: "Vos podcasts"
              },
            },
            filters: {
              type: 'solo',
              soloTagId: '5ef0b1064fc7ef1f5fcc1430',
              sort: (category) => {
                // seulement et dans cet ordre :
                // Cancers, Maladies chroniques, Maladies rares, Autres
                // 1) on clone les tags
                const clonedCategory = $clone(category)
              const subTags = clonedCategory.tags
              const displaidTags = []
              displaidTags.push(findTag('commerce', subTags))
              displaidTags.push(findTag('r-h', subTags))
              displaidTags.push(findTag("innovation", subTags))
              displaidTags.push(findTag('resultats-et-marche', subTags))
              displaidTags.push(findTag('vision-strategiques', subTags))
              displaidTags.push(findTag('poles', subTags))


              clonedCategory.tags = displaidTags.filter(Boolean)
              return clonedCategory
              },
            },

            heading: {
              color: "#757575",
              canRadio: false,
              dark: true,
              buttonColor: "white",
              buttonLight: true,
              backgroundImage(context) {
                return context.$voicer.isMobile
                  ? require('~/assets/images/CouvertureRTU.png')
                  : require('~/assets/images/CouvertureRTU.png')
              },
            }
          },
        ],
      },
    ],
  },
  configEnvironments[process.env.BROCOLI_API_MODE],
  configLocal,
  envConfig
);

module.exports = transformConfig(endConfig);

